/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Josefin Sans", sans-serif;
  /* background: #212121; */
  background-color: #000000;
  color: #ffffff;
}

/*
=============== 
Navbar
===============
*/

.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.nav-toggle {
  font-size: 1.5rem;
  color: var(--clr-primary-5);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.nav-toggle:hover {
  color: var(--clr-primary-1);
  transform: rotate(90deg);
}
.logo {
  height: 40px;
}

.social-icons {
  display: none;
}
.links-container {
  /* display: none; */
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
}
.show-container {
  /* height: 10rem; */
  display: flex;
}

.links {
  padding: 7px;
  cursor: pointer;
}
@media (min-width: 800px) {
  .nav-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 1rem; */
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .social-icons {
    display: flex;
  }
  .social-icons a {
    margin: 0 0.5rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: var(--clr-primary-7);
  }
}

.join-discord {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  border-radius: 12px;
  color: #ffffff !important;
}

/* .mask {
  background: url("./img/mask.png");
  background-size: contain;
  background-repeat: no-repeat;
} */

.line {
  background: linear-gradient(
    180deg,
    rgba(91, 110, 206, 0) 0.83%,
    #5b6ece 15.96%
  );
}

.line2 {
  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0.83%,
    #c4c4c4 11.77%,
    #c4c4c4 89.9%,
    rgba(196, 196, 196, 0) 100%
  );
}
/* 
.active {
  display: none;
}

.active:active {
  display: block;
} */

.tab {
  font-weight: 400 !important;
  font-size: 30px !important;
  line-height: 72px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  margin-left: 10px !important;
}

@media (max-width: 700px) {
  .tab {
    font-size: 22px !important;
  }
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #5b6ece !important;
}

/* .custom {
  height: 200px;
  width: 200px;
} */

.slick-next:before {
  /* content: '→'; */
  display: none;
}

.slick-prev:before {
  /* content: '←'; */
  display: none;
}

.slick-dots li button:before {
  color: white !important;
}

.box {
  position: absolute;
  left: 50%;
  /* top: 50%; */
  transform: translate(-50%, -50%);
}

.hero__image {
  height: 420px;
  width: 420px;
  object-fit: contain;
}

.team__cont {
  min-width: 364px;
  min-height: 480px;
}

.bite__box {
  background-color: rgba(11, 47, 91, 0.2);
}
